import { createContext } from 'preact';

import { Types } from './constants';

interface BaseChatInfo {
  toUserId: string;
}

export interface ChatInfoAlbum extends BaseChatInfo {
  chatType: Types.PHOTO | Types.CLIP;
  previewImage: string;
  mediaId: string;
  questionId: never;
}

export interface ChatInfoQA extends BaseChatInfo {
  chatType: Types.QA;
  previewImage: never;
  mediaId: never;
  questionId: string;
}

export type ChatInfo = ChatInfoAlbum | ChatInfoQA;

const ChatInfoContext = createContext<ChatInfo | undefined>(undefined);

export default ChatInfoContext;
