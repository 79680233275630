import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import style from './style.scss';

const ViewersCount = ({ plain, viewers, className }) => (
  <div className={clsx(className, { [style.viewerCountWrapper]: !plain })}>
    <div className={style.viewerCount}>
      <FontAwesomeIcon icon={faEye} />
      <span>{viewers}</span>
    </div>
  </div>
);

export default ViewersCount;
