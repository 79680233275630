import { Ref } from 'preact';
import { HTMLAttributes, useEffect, useState } from 'preact/compat';

import ProtectedImg from '@/components/ProtectedImg';
import { cn } from '@/lib/utils';

interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  imageRef?: Ref<HTMLImageElement>;
}

const Image = ({ imageRef, className, onLoad, onError, ...rest }: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleOnError: NonNullable<typeof onError> = (event) => {
    setIsError(true);
    onError?.(event);
  };

  const handleOnLoad: NonNullable<typeof onLoad> = (event) => {
    setIsLoaded(true);
    onLoad?.(event);
  };

  useEffect(() => {
    setIsLoaded(false);
    setIsError(false);
  }, [rest.src]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <ProtectedImg
      className={cn(className, !isLoaded && !isError && 'preflight preflight-img skeleton bg-grey-5')}
      onLoad={handleOnLoad}
      onError={handleOnError}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={imageRef}
    />
  );
};

export default Image;
