import { createContext } from 'preact';

export interface ChatConfiguration {
  canShowWelcomeMessage?: boolean;
  allowsMembersOnlyMode?: boolean;
  showPinButton?: boolean;
  showMediaUploadButton?: boolean;

  backgroundColor: string;
  foregroundColor: string;

  scrollbarBackgroundColor: string;
  scrollbarBorderColor: string;

  selectedBackgroundColor: string;
  pinnedBackgroundColor: string;
}

interface DerivedChatConfiguration extends ChatConfiguration {
  cssVariables: Record<string, string>;
}

export const legacyDesktopChatConfiguration: ChatConfiguration = {
  canShowWelcomeMessage: true,
  allowsMembersOnlyMode: true,
  showPinButton: true,
  backgroundColor: 'var(--primaryGreen)',
  foregroundColor: 'var(--white)',
  scrollbarBackgroundColor: 'var(--white)',
  scrollbarBorderColor: 'var(--primaryGreen)',
  selectedBackgroundColor: 'var(--hunterGreen)',
  pinnedBackgroundColor: 'var(--hunterGreen)',
};

export const legacyAlternateChatConfiguration: ChatConfiguration = {
  backgroundColor: 'var(--white)',
  foregroundColor: 'var(--charcoal)',
  scrollbarBackgroundColor: '#E7E7E7',
  scrollbarBorderColor: 'var(--white)',
  selectedBackgroundColor: '#F7F7F7',
  pinnedBackgroundColor: '#F0F0F0',
};

export const ChatConfigurationContext = createContext<DerivedChatConfiguration | undefined>(undefined);
