import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Box, BoxProps } from 'grommet';

import style from './style.scss';

interface LoaderSpinnerProps {
  color?: string;
  fill?: BoxProps['fill'];
  className?: string;
  absolute?: boolean;
}

const LoaderSpinner = ({ color = 'white', fill, className, absolute }: LoaderSpinnerProps) => (
  <Box
    className={clsx(style.animation, className, { [style.absolute]: absolute })}
    fill={fill}
    justify="center"
    align="center"
  >
    <FontAwesomeIcon icon={faSpinner} color={color} spin style={{ verticalAlign: 'middle', fontSize: '50px' }} />
  </Box>
);

export default LoaderSpinner;
