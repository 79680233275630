import { Layer } from 'grommet';
import { FunctionalComponent, h } from 'preact';

import StatusContent from './StatusContent';

export const defaultErrorMsg = (
  <>
    <span>Something went wrong.</span>
    <br />
    <span>Please try again.</span>
  </>
);

const ErrorModal: FunctionalComponent<{ text?: string; onClose?: () => void }> = ({ text = defaultErrorMsg, onClose }) => (
  <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
    {/* @ts-ignore */}
    <StatusContent type="error" text={text} onClose={onClose} />
  </Layer>
);

export default ErrorModal;
