import { faFacebookSquare, faReddit, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faCheck,
  faEnvelope,
  faLink,
  faShareAlt,
  faShareSquare,
  faSpinner,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutlineButton, PrimaryButton } from 'Components/Buttons';
import CloseButton from 'Components/modals/CloseButton';
import ErrorModal from 'Components/modals/Error';
import RoundButton from 'Components/RoundButton';
import { Drop, Heading, Layer, Text } from 'grommet';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { API_BASE_URL, buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { removeMediaFromMyAlbum } from '@/redux/slices/myAlbum/actions';

import { androidDevice, iOSDevice } from '../../../helpers';
import { removeMediaFromAlbum } from '../../../routes/habitat/components/Album/actions';
import { closeShareModal } from '../shareModalSlice/actions';
import style from './style.scss';

const DeleteButton = ({ mediaId: id, type, onDeleteSuccess }) => {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { put, loading, response, error } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  const onClose = () => {
    setShowConfirmation(false);
  };

  const deleteMedia = async () => {
    await put(type === 'photo' ? `/photos/${id}` : `/videos/${id}`, { disabled: true });

    if (response.ok) {
      dispatch(removeMediaFromAlbum({ id }));
      dispatch(removeMediaFromMyAlbum({ id }));
      dispatch(closeShareModal());
      onClose();
      onDeleteSuccess();
    }
  };

  return (
    <>
      <button type="button" aria-label="Delete Button" className={style.delete} onClick={() => setShowConfirmation(true)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
      {showConfirmation && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose} background="white">
          <div className={style.deletePopupContainer}>
            <div>
              <CloseButton varient="grey" onClick={onClose} className={style.close} />
              <Heading level="4" color="var(--charcoal)" textAlign="center">
                Are you sure you want to delete
                <br />
                this moment?
              </Heading>
            </div>
            <div className={style.buttons}>
              <OutlineButton margin={{ right: '10px' }} label="Yes" loading={loading} onClick={deleteMedia} />
              <PrimaryButton label="No" margin={{ left: '10px' }} disabled={loading} onClick={onClose} />
            </div>
            {error && (
              <Text textAlign="center" size="xlarge" color="var(--red)">
                Something went wrong. Please try again.
              </Text>
            )}
          </div>
        </Layer>
      )}
    </>
  );
};
export const DeleteMediaButton = memo(DeleteButton);

export const generateFacebookURL = (html, hashtags) => {
  const shareURL = new URL('https://www.facebook.com/sharer/sharer.php');
  shareURL.searchParams.append('u', html);
  if (isArray(hashtags) && hashtags.length > 0) {
    shareURL.searchParams.append('hashtag', `#${hashtags[0]}`);
  }
  return shareURL.href;
};

export const FacebookShareLink = ({ htmlURL, hashtags, onClick }) => (
  <a
    className={style.fbShare}
    href={generateFacebookURL(htmlURL, hashtags)}
    target="_blank"
    rel="noreferrer"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faFacebookSquare} />
  </a>
);

export const WebShare = ({ logShare, htmlURL, photoURL, videoURL }) => {
  const [showLoading, setShowLoading] = useState(false);

  const webShareHandler = async () => {
    try {
      setShowLoading(true);
      const fileUrl = (photoURL || videoURL).replace(/^.*\/\/[^\/]+/, '');
      const filename = fileUrl.split('/').pop();
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const file = new File([blob], filename, { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({ files: [file] });
      } else {
        await navigator.share({ url: htmlURL });
      }
      logShare('webShare');
    } catch (error) {
      console.error('Webshare error', error);
    } finally {
      setShowLoading(false);
    }
  };
  return (
    <button type="button" onClick={webShareHandler} className={style.shareButton}>
      {showLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      {!showLoading && androidDevice() && <FontAwesomeIcon icon={faShareAlt} />}
      {!showLoading && iOSDevice() && <FontAwesomeIcon icon={faShareSquare} />}
    </button>
  );
};

// Email, Twitter and Reddit share buttons are no longer in use but the functionality is already
// implemented therefore they are added here to keep their reference if we want to re-add them
const Email = ({
  url, // photo URL
  logShare,
  shareButtonWidth,
  shareButtonRadius,
}) => {
  const [showEmailError, setShowEmailError] = useState();
  const [showEmailSuccess, setShowEmailSuccess] = useState();
  const { error, post, response, loading } = useFetch(API_BASE_URL, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const sendEmail = async () => {
    await post('/email/snapshot', { imageUrl: url });
    logShare('email');
  };

  useEffect(() => {
    if (error) {
      setShowEmailError(true);
    } else if (response.ok) {
      setShowEmailSuccess(true);
      setTimeout(setShowEmailSuccess, 2000);
    }
  }, [error, response, response.ok]);

  return (
    <div>
      <RoundButton
        onClick={sendEmail}
        className={style.shareIcon}
        disabled={loading || showEmailSuccess}
        backgroundColor="#F18C43"
        color="white"
        width={shareButtonWidth}
        loading={loading}
        radius={shareButtonRadius}
      >
        {!loading && <FontAwesomeIcon icon={showEmailSuccess ? faCheck : faEnvelope} />}
      </RoundButton>
      {showEmailError && <ErrorModal onClose={() => setShowEmailError(false)} />}
    </div>
  );
};

export const generateTwitterURL = (html, hashtags, text, handle) => {
  const shareURL = new URL('https://twitter.com/intent/tweet');

  if (isArray(hashtags) && hashtags.length > 0) {
    shareURL.searchParams.append('hashtags', hashtags.join(','));
  }

  if (!isNil(text) && !isEmpty(text)) {
    shareURL.searchParams.append('text', text);
  }

  if (!isNil(handle) && !isEmpty(handle)) {
    shareURL.searchParams.append('via', handle);
  }

  shareURL.searchParams.append('tw_p', 'tweetbutton');
  shareURL.searchParams.append('url', html);
  return shareURL.href;
};
const Twitter = ({ htmlURL, logShare, shareButtonWidth, shareButtonRadius, hashtags }) => (
  <a href={generateTwitterURL(htmlURL, hashtags)} target="_blank" rel="noreferrer" onClick={() => logShare('twitter')}>
    <RoundButton
      backgroundColor="#1DA1F2"
      color="white"
      width={shareButtonWidth}
      radius={shareButtonRadius}
      className={style.shareIcon}
    >
      <FontAwesomeIcon icon={faTwitter} />
    </RoundButton>
  </a>
);

export const generateRedditURL = (html, title = '') => {
  const shareURL = new URL('https://www.reddit.com/submit');

  let modifiedTitle = title.trim();
  // Reddit has a limit for title length which is 300 character
  if (modifiedTitle.length > 300) {
    modifiedTitle = `${modifiedTitle.substring(0, 296)} ...`;
  }
  shareURL.searchParams.append('title', modifiedTitle);
  shareURL.searchParams.append('url', html);

  return shareURL.href;
};
const Reddit = ({ title, htmlURL, logShare, shareButtonWidth, shareButtonRadius }) => (
  <a href={generateRedditURL(htmlURL, title)} target="_blank" rel="noreferrer" onClick={() => logShare('reddit')}>
    <RoundButton
      backgroundColor="#ff4500"
      color="white"
      width={shareButtonWidth}
      radius={shareButtonRadius}
      className={style.shareIcon}
    >
      <FontAwesomeIcon icon={faReddit} />
    </RoundButton>
  </a>
);

export const CopyLink = ({ link, onClick }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const targetRef = useRef();

  useEffect(() => {
    let timeout;

    if (linkCopied) {
      timeout = setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [linkCopied]);

  const copyToClipboardBtnHandler = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() => setLinkCopied(true))
        .catch((err) => console.error('Error while copying link via navigator.clipboard', err));
    } else {
      // if clipboard is not supported, attempt to use old alternative (deprecated)
      console.warn('clipboard is not supported, copy via execCommand.');
      const elem = document.createElement('input');
      elem.value = link;
      document.body.appendChild(elem);
      elem.select();

      try {
        document.execCommand('copy');
        setLinkCopied(true);
      } catch (err) {
        console.error('Error while copying link', err);
      } finally {
        document.body.removeChild(elem);
      }
    }

    onClick();
  };

  return (
    <>
      <button ref={targetRef} type="button" className={style.copyLinkShareButton} onClick={copyToClipboardBtnHandler}>
        <FontAwesomeIcon icon={faLink} />
      </button>

      {targetRef?.current && linkCopied && (
        <Drop align={{ bottom: 'top' }} target={targetRef.current} className={style.copyLinkPopupContainer}>
          <div className={style.wrapper}>Copied</div>
        </Drop>
      )}
    </>
  );
};
