import { ComponentProps } from 'preact';
import { forwardRef } from 'preact/compat';

type ProtectedImgProps = ComponentProps<'img'>;

const preventDefault = (e: Event): void => e.preventDefault();

const ProtectedImg = forwardRef<HTMLImageElement, ProtectedImgProps>(({ alt, style, ...props }, ref) => (
  <img
    draggable={false}
    onContextMenu={preventDefault}
    alt={alt}
    ref={ref}
    style={{
      WebkitTouchCallout: 'none',
      ...(style as object),
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

export default ProtectedImg;
