import habitatAlbumSlice from './slice';

export const {
  setAlbumData,
  appendAlbumData,
  updateHabitatAlbumData,
  changeContentVisibility,
  removeMediaFromAlbum,
  addTagToContent: addTagToHabitatAlbumContent,
  removeTagFromContent: removeTagFromHabitatAlbumContent,
} = habitatAlbumSlice.actions;
