import {
  faAngleLeft,
  faClosedCaptioning,
  faEllipsisVertical,
  faExpand,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { faCompress, faVolume, faVolumeMute } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { PrimaryButton } from 'Components/Buttons';
import CameraSelector from 'Components/CameraSelector';
import RoundButton from 'Components/RoundButton';
import { Box, Grommet, RangeInput as InputRange, Text } from 'grommet';
import { h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { logGAEvent } from 'Shared/ga';

import { formatSecondsToVideoDuration } from '../../helpers';
import { useIsHabitatTabbed, useIsMobileSize } from '../../hooks';
import { setShowVivTranscript } from '../../redux/actions';
import style from './style.scss';

const customThemeRangeInputVolume = {
  global: {
    spacing: '12px',
  },
  rangeInput: {
    track: {
      height: '6px',
      extend: () => `border-radius: 10px`,
      lower: {
        color: 'white',
        opacity: 0.7,
      },
      upper: {
        color: 'white',
        opacity: 0.3,
      },
    },
    thumb: {
      color: 'white',
    },
  },
};

const customThemeRangeInputSeekbar = {
  global: {
    spacing: '12px',
  },
  rangeInput: {
    track: {
      height: '6px',
      extend: () => `border-radius: 10px`,
      lower: {
        color: '#2E2D2D',
        opacity: 0.7,
      },
      upper: {
        color: 'white',
        opacity: 0.3,
      },
    },
    thumb: {
      color: '#2E2D2D',
    },
  },
};

const VideoControls = (
  {
    showControls,
    showPlayControl,
    showVolumeControl,
    showFullscreenControl,
    showSeekBar,
    showTimeStats,
    showPIPControl,
    singleButton = false,
    onPauseHandler,
    isPlaying,
    isLoading,
    videoLength,
    timeElapsed,
    updateVideoTimeHandler,
    showSwitchCameraControl,
    onChangeFullscreen,
    mode = 'webrtc',
    muted = true,
    showVivTranscriptControl = false,
    vivSupportsTranscript,
    showVivTranscript = false,
    setShowVivTranscriptAction,
    onNextHandler,
    onMuteChange,
  },
  ref,
) => {
  const [showVolumeBar, setShowVolumeBar] = useState(false);
  const [isMuted, setIsMuted] = useState();
  const [volume, setVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobileSize = useIsMobileSize();
  const [expandControls, setExpandControls] = useState(!isMobileSize);
  const volumeButtonRef = useRef(null);
  const isTabbedView = useIsHabitatTabbed();

  useEffect(() => {
    setIsMuted(muted);
  }, [muted]);

  useEffect(() => {
    const handleFullscreenMode = () => {
      const isFullscreen = document.fullscreenElement || document.webkitCurrentFullScreenElement;
      setIsFullscreen(isFullscreen);
      onChangeFullscreen(isFullscreen);
    };

    document.addEventListener('fullscreenchange', handleFullscreenMode);
    document.addEventListener('webkitfullscreenchange', handleFullscreenMode);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenMode);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenMode);
    };
  }, [setIsFullscreen, onChangeFullscreen]);

  // TODO: we need to refactor this
  // there are a lot of references passed between different components
  // and this is receiving a reference that's not a video element
  // so the "video.play()" is throwing errors
  // eslint-disable-next-line react/destructuring-assignment
  const video = ref.current;

  const togglePlay = (evt) => {
    evt.stopPropagation();
    if (typeof onPauseHandler === 'function') {
      onPauseHandler();
    }
  };

  const toggleMute = (evt) => {
    evt.stopPropagation();
    video.muted = !video.muted;
    setIsMuted(video.muted);
    onMuteChange?.(video.muted);
  };

  const onVolumeChange = ({ target: { value } }) => {
    video.volume = value;
    setVolume(value);
    video.muted = value === '0';
    setIsMuted(video.muted);
    onMuteChange?.(video.muted);
  };

  const openFullscreen = (evt) => {
    evt.stopPropagation();
    if (!isFullscreen) {
      video.requestFullscreen(mode);
    } else {
      video.exitFullscreen();
    }
  };

  const togglePictureInPicture = (evt) => {
    evt.stopPropagation();
    video.requestPictureInPicture();
  };

  const onChangeCCState = () => {
    logGAEvent('change-cc-state', showVivTranscript ? 'False' : 'True');
    setShowVivTranscriptAction(!showVivTranscript);
  };

  if (!showControls || !video) {
    return null;
  }

  return (
    <Box className={clsx(style.videoControls, { [style.mobile]: isTabbedView })} direction="column" align="start">
      {typeof onNextHandler === 'function' && (
        <Box direction="row" fill pad={{ vertical: 'xsmall' }} margin={{ bottom: 'small' }} justify="end">
          <PrimaryButton label="Next Video" onClick={() => onNextHandler()} />
        </Box>
      )}

      <Box direction="row" width="100%">
        {singleButton && isMobileSize && (
          <RoundButton
            onClick={() => setExpandControls(!expandControls)}
            width="28"
            backgroundColor="var(--charcoal)"
            color="white"
            className={style.showControlsButton}
          >
            <FontAwesomeIcon icon={expandControls ? faAngleLeft : faEllipsisVertical} />
          </RoundButton>
        )}

        {/* eslint-disable-next-line max-len */}
        <div className={clsx(style.buttonsWrapper, singleButton && !expandControls && isMobileSize && style.hidden)}>
          {showPlayControl && (
            <RoundButton onClick={togglePlay} width="28" backgroundColor="var(--charcoal)" color="white" loading={isLoading}>
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </RoundButton>
          )}

          {showVolumeControl && (
            <div
              ref={volumeButtonRef}
              onMouseEnter={() => setShowVolumeBar(true)}
              onMouseLeave={() => setShowVolumeBar(false)}
              className={clsx(style.volumeWrapper, {
                [style.expanded]: showVolumeBar && !isTabbedView,
              })}
              data-testid="video-volume-control-button"
            >
              <RoundButton onClick={toggleMute} width="28" backgroundColor="var(--charcoal)" color="white">
                <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolume} />
              </RoundButton>
              {!isTabbedView && showVolumeBar && volumeButtonRef.current && (
                <div className={clsx(style.volume, { [style.expanded]: showVolumeBar })}>
                  <Grommet theme={customThemeRangeInputVolume} className={style.rangeInput}>
                    <InputRange
                      min={0}
                      max={1}
                      step={0.1}
                      value={volume}
                      onChange={onVolumeChange}
                      data-testid="volume-control-input"
                    />
                  </Grommet>
                </div>
              )}
            </div>
          )}

          {showPIPControl && (
            <RoundButton onClick={togglePictureInPicture} width="28" backgroundColor="var(--charcoal)" color="white">
              <FontAwesomeIcon icon={faCompress} />
            </RoundButton>
          )}

          {showFullscreenControl && (
            <RoundButton onClick={openFullscreen} width="28" backgroundColor="var(--charcoal)" color="white">
              <FontAwesomeIcon icon={faExpand} />
            </RoundButton>
          )}

          {showSwitchCameraControl && <CameraSelector />}

          {mode === 'vod' && (
            <div className={style.timelineWrapper}>
              {showSeekBar && (
                <div className={style.seekBar}>
                  <Grommet theme={customThemeRangeInputSeekbar} className={style.rangeInput}>
                    <InputRange
                      min={0}
                      max={videoLength}
                      step={1}
                      value={timeElapsed}
                      onChange={({ target: { value } }) => updateVideoTimeHandler(value)}
                    />
                  </Grommet>
                </div>
              )}
              {showTimeStats && (
                <div className={style.timeStats}>
                  <Text color="white" weight={700} size="large" margin={{ left: '5px' }}>
                    {`${formatSecondsToVideoDuration(timeElapsed)} / ${formatSecondsToVideoDuration(videoLength)}`}
                  </Text>
                </div>
              )}
            </div>
          )}

          {showVivTranscriptControl && vivSupportsTranscript && (
            <RoundButton
              onClick={onChangeCCState}
              width="28"
              backgroundColor={showVivTranscript ? 'white' : 'var(--charcoal)'}
              color={showVivTranscript ? 'var(--charcoal)' : 'white'}
            >
              <FontAwesomeIcon icon={faClosedCaptioning} />
            </RoundButton>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default connect(
  ({ mainStream: { showVivTranscript, vivSupportsTranscript } }) => ({
    showVivTranscript,
    vivSupportsTranscript,
  }),
  {
    setShowVivTranscriptAction: setShowVivTranscript,
  },
  null,
  {
    forwardRef: true,
  },
)(forwardRef(VideoControls));
