import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { h } from 'preact';

import style from './style.scss';

/**
 * Renders a round button with customizable styles and functionality.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.backgroundColor] - The background color of the button.
 * @param {string} [props.color] - The text color of the button.
 * @param {number} [props.width] - The width of the button.
 * @param {Function} [props.onClick] - The function to call when the button is clicked.
 * @param {string} [props.className] - Additional CSS class names for the button.
 * @param {boolean} [props.disabled] - Indicates if the button is disabled.
 * @param {boolean} [props.loading] - Indicates if the button is in a loading state.
 * @param {import('preact/compat').ReactNode} [props.children] - The content to be displayed inside the button.
 * @param {number} [props.radius] - The border radius of the button.
 * @return {import('preact').JSX.Element} The JSX element representing the round button.
 */
const RoundButton = ({ backgroundColor, color, width, onClick, className, disabled, loading, children, radius }) => (
  <button
    type="button"
    className={clsx(style.roundButton, className)}
    onClick={onClick}
    disabled={disabled}
    style={{
      width: `${width}px`,
      height: `${width}px`,
      minWidth: `${width}px`,
      borderRadius: radius ? `${radius}px` : '50%',
      backgroundColor,
      color,
    }}
  >
    <div className={style.btnWrapper}>
      {!loading && children}
      {loading && (
        <div className={style.loaderWrapper}>
          <FontAwesomeIcon className={style.loader} icon={faSpinner} spin />
        </div>
      )}
    </div>
  </button>
);

export default RoundButton;
