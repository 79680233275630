import myAlbumSlice from '.';

export const {
  setFilterOptions,
  setQueryURL,
  toggleAnimalFilter,
  toggleZooFilter,
  setAnimalFilter,
  setZooFilter,
  setAlbumData,
  setTypeData,
  setPageData,
  appendAlbumData,
  changeContentVisibility,
  updateAlbumData,
  removeMediaFromMyAlbum,
  addTagToContent: addTagToMyAlbumContent,
  removeTagFromContent: removeTagFromMyAlbumContent,
  setShareModalMediaId,
  setShareModalData,
  closeShareModal,
  setHabitat,
} = myAlbumSlice.actions;
