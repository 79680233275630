import { faHeart as faHeartOutline } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from 'Shared/fetch';
import useFetch from 'use-http';

import { updateAlbumData } from '@/redux/slices/myAlbum/actions';

import { updateHabitatAlbumData } from '../../routes/habitat/components/Album/actions';
import style from './style.scss';

const LikeButton = ({ data, onClick = () => {}, mediaId, type, className }) => {
  const logged = useSelector((state) => state.user.logged);
  const dispatch = useDispatch();
  const [contentLikes, setContentLikes] = useState(data);
  const { put } = useFetch(API_BASE_URL, { credentials: 'include', cachePolicy: 'no-cache' });

  useEffect(() => {
    setContentLikes(data);
  }, [data, mediaId]);

  const likeContent = async () => {
    if (!logged) {
      return;
    }

    try {
      onClick();
      if (mediaId) {
        await put(`${type === 'photo' ? 'photos' : 'videos'}/${mediaId}/like`);
      }

      setContentLikes((prevState) => {
        const isLiked = !prevState.isLiked;
        const updated = {
          isLiked,
          likes: isLiked ? prevState.likes + 1 : prevState.likes - 1,
        };

        // we need to update the state so when users navigate back and forth they see same value
        if (mediaId) {
          const payload = { id: mediaId, data: { isLiked, usersLike: updated.likes } };
          dispatch(updateHabitatAlbumData(payload));
          dispatch(updateAlbumData(payload));
        }
        return updated;
      });
    } catch (err) {
      console.error('Error trying to like content:', err);
    }
  };

  return (
    <button
      type="button"
      onClick={likeContent}
      className={clsx(className, style.likeButtonContainer, { [style.guest]: !logged })}
    >
      <div className={clsx(style.likeButtonHeartContainer, { [style.liked]: contentLikes.isLiked || !logged })}>
        <FontAwesomeIcon
          className={style.likeButtonHeart}
          icon={contentLikes.isLiked || !logged ? faHeart : faHeartOutline}
        />
      </div>
      <span className={style.likeButtonCount}>
        {contentLikes.likes < 1000 ? contentLikes.likes : `${(contentLikes.likes / 1000).toFixed(1)}k`}
      </span>
    </button>
  );
};

export default LikeButton;
